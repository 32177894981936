import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Swiper from "react-id-swiper"
// tslint:disable-next-line: no-submodule-imports
import "swiper/css/swiper.css" // imports node module css -- not sure why this is needed but it is...
import "./BrowseVehicles.scss"

const VehicleSwiperParams = {
  breakpoints: {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 16,
    },
    640: {
      slidesPerView: 2.2,
      spaceBetween: 32,
    },
    960: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
    1280: {
      slidesPerView: 4,
      spaceBetween: 32,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
}

const BrowseVehicles: React.FC = props => {
  const swipeVehicles = props.vehicles
  return (
    <div className="browse-vehicles-container">
      <div className="browse-vehicles-wrapper">
        <h2 className="f-h2">View more vehicles</h2>
        <Swiper {...VehicleSwiperParams}>
          {swipeVehicles.map((item, key: number) => (
            <div key={key}>
              <Link className="f-link" to={item.node.frontmatter.path}>
                <Img fluid={item.node.frontmatter.hero.childImageSharp.fluid} />
                <p>{item.node.frontmatter.title}</p>
              </Link>
            </div>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default BrowseVehicles
