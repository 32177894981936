import BannerCTA from "@components/BannerCTA/BannerCTA"
import Intro from "@components/Intro/Intro"
import Layout from "@components/Layout/Layout"
import { MDXRenderer } from "gatsby-plugin-mdx"
import "@styles/global.scss"
import React from "react"
import { graphql } from "gatsby"
import BrowseVehicles from "@components/BrowseVehicles/BrowseVehicles"

const OtherVehiclesToShow = 20

export default function PageTemplate({ data }) {
  // get random listing of vehicles for recommended slider
  const collectionList = data.CollectionList.edges
  var swipeVehicles = chooseRandomVehicles(collectionList, OtherVehiclesToShow)

  return (
    <Layout
      metaPageTitle={`${data.mdx.frontmatter.pageTitle} | Vehicles at the Hagerty Learning Garage`}
      metaDescription={`${data.mdx.frontmatter.pageTitle} is a vehicle on display at the Hagerty Learning Garage. Learn more about how it came to be in Traverse City.`}
      darkTheme={true}
    >
      <Intro
        title={data.mdx.frontmatter.title}
        body={data.mdx.frontmatter.summary}
        backgroundHero={
          data.mdx.frontmatter.backgroundHero.childImageSharp.fixed.src
        }
        hero={data.mdx.frontmatter.hero.childImageSharp.fluid}
        vehicleBio={true}
      />
      <div className="wrapper constrain-copy content">
        <div className="content-wrapper">
          <MDXRenderer
            contentImages={data.mdx.frontmatter.contentImages}
            otherVehicles={swipeVehicles}
          >
            {data.mdx.body}
          </MDXRenderer>
        </div>
      </div>
      <BrowseVehicles vehicles={swipeVehicles} />
      <BannerCTA />
    </Layout>
  )
}

function chooseRandomVehicles(vehicles, length) {
  const vehiclesLength = vehicles.length - 1
  let randomVehicles = []
  let chosenVehicles = []

  for (var i = 0; i < length; i++) {
    var rando = random(0, vehiclesLength)
    // make sure we don't have duplicate vehicles in the list
    if (!chosenVehicles.includes(rando)) {
      chosenVehicles.push(rando)
      randomVehicles.push(vehicles[rando])
    }
  }

  return randomVehicles
}

function random(mn, mx) {
  return Math.round(Math.random() * (mx - mn) + mn)
}

// { "id": "1b1c58d4-a639-5a83-baae-b9a1de2b0e82" }
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        pageTitle
        metaDescription
        title
        summary
        backgroundHero {
          childImageSharp {
            fixed(width: 1800, quality: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        hero {
          childImageSharp {
            fluid(maxWidth: 1100, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        contentImages {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    CollectionList: allMdx {
      edges {
        node {
          id
          frontmatter {
            title
            path
            hero {
              childImageSharp {
                fluid(maxWidth: 320, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
